<template>
  <v-app data-testid="app" :style="appStyles">
    <v-main>
      <AppBar
        v-if="
          route.path !== '/login' &&
          !route.path.includes('/approval-proposal') &&
          !route.path.includes('/approval-requisition')
        "
        style="z-index: 7"
      />

      <transition name="fadeRouter" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <SessionExpiredDialog />
    <ReloadDialog />
  </v-app>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRoute, useVuetify } from "@/composables";
import { useThemeStore } from "@/stores";

import AppBar from "@/components/AppBar";
import SessionExpiredDialog from "@/components/znap/SessionExpiredDialog";
import ReloadDialog from "@/components/znap/ReloadDialog";

const route = useRoute();
const vuetify = useVuetify();

const themeStore = useThemeStore();

const appStyles = computed(() => {
  return {
    background: "#ddd",
    "background-image": 'url("/img/background.png")',
    "background-position": "center",
    "background-repeat": "no-repeat",
    "background-size": "cover",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover",
    "overflow-y": "hidden",
    "scroll-behavior": "smooth",
    "line-height": route.path.includes("editor") ? "1.2" : "1.5",
  };
});

function setTheme() {
  const colours = require(`@/plugins/themes/${themeStore.theme}`);
  vuetify.theme.themes.light = colours.default;
}

onMounted(() => {
  setTheme();
});
</script>

<style>
:root {
  --primary-color: #2b81d6;
}

html {
  scroll-behavior: smooth;
}

.v-navigation-drawer {
  z-index: 10000 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>

<style scoped>
/* #app {
  background: #ddd;
  background-image: url("../public/img/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  overflow-y: hidden;
  scroll-behavior: smooth;
} */

body {
  overflow-y: hidden !important;
}

.fadeRouter-enter {
  opacity: 0;
}

.fadeRouter-enter-active {
  transition: opacity 0.28s ease;
}

.fadeRouter-leave-active {
  transition: opacity 0.28s ease;
  opacity: 0;
}

.v-img {
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
</style>
