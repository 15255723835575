<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-badge
          avatar
          bottom
          :color="sessionBadgeColor"
          offset-x="18"
          offset-y="18"
        >
          <template v-if="authStore.user">
            <v-avatar v-if="authStore.user.photo">
              <v-img :src="authStore.user.photo" :aspect-ratio="1 / 1">
                <template v-slot:placeholder>
                  <v-skeleton-loader type="avatar"></v-skeleton-loader>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar v-else>
              <v-icon color="primary" class="text-h3"
                >mdi-account-circle</v-icon
              >
            </v-avatar>
          </template>
          <v-avatar v-else>
            <v-icon color="primary" class="text-h3">mdi-account-circle</v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <v-sheet max-width="350px">
      <v-list>
        <v-list-item-group>
          <v-list-item
            @click="route.path !== '/profile' ? router.push('/profile') : false"
          >
            <v-list-item-icon class="mr-4">
              <template v-if="authStore.user">
                <v-avatar v-if="authStore.user.photo">
                  <v-img
                    :src="authStore.user.photo"
                    :alt="'Foto de ' + authStore.user.name"
                    :aspect-ratio="1 / 1"
                  />
                </v-avatar>
                <v-icon v-else color="primary" x-large
                  >mdi-account-circle</v-icon
                >
              </template>
              <v-icon v-else color="primary" x-large>mdi-account-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-h6">{{
                authStore.user ? authStore.user.name : ""
              }}</v-list-item-title>
              <v-list-item-title class="text-caption">{{
                authStore.user ? authStore.user.email : ""
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-row class="py-2 px-4">
            <v-col class="d-flex align-center">
              <v-icon small color="primary" class="mr-2">mdi-clock</v-icon>
              <span class="text-caption">{{ tokenExp }}</span>
              <v-spacer></v-spacer>
              <v-btn x-small text color="primary" @click="renewSession()">
                Renovar
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-list-item @click="logout()">
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script setup>
import { onMounted, ref, computed, onBeforeUnmount } from "vue";
import { useRoute, useError, useRouter } from "@/composables";
import { useAuthStore } from "@/stores";
import moment from "moment";

const route = useRoute();
const router = useRouter();
const { errorFunction, toast } = useError();

const authStore = useAuthStore();

const sessionBadgeColor = ref("success");
const now = ref(new Date());
const interval = ref(null);

const tokenExp = computed(() => {
  let tokenExp = "";

  if (authStore.exp && !isValidToken(authStore.exp)) {
    if (
      window.location.pathname.includes("/approval-proposal") ||
      window.location.pathname.includes("/approval-requisition")
    ) {
      authStore.setSessionExpiredDialog(false);
    } else {
      authStore.setSessionExpiredDialog(true);
    }

    tokenExp = "Sessão expirada";
    return;
  }

  let nowValue = moment(now.value, "hh:mm:ss");
  let exp = moment.unix(authStore.exp, "hh:mm:ss");
  let duration = moment.duration(exp.diff(nowValue))._data;

  let hours =
    duration.hours.toString().length < 2
      ? `0${duration.hours}`
      : `${duration.hours}`;

  let minutes =
    duration.minutes.toString().length < 2
      ? `0${duration.minutes}`
      : `${duration.minutes}`;

  let seconds =
    duration.seconds.toString().length < 2
      ? `0${duration.seconds}`
      : `${duration.seconds}`;

  let time = `${hours}:${minutes}:${seconds}`;

  setSessionBadgeColor(hours, minutes);

  tokenExp = `Sessão expira em ${time}`;

  return tokenExp;
});

async function renewSession() {
  console.log(authStore);
  const auth = await authStore.hashAuthenticate(authStore.hash);

  if (auth === "success") {
    toast.success("Sessão renovada com sucesso");
  } else {
    let err = auth;
    errorFunction(err);
  }
}

function isValidToken(tokenExp) {
  let nowValue = Math.floor(Date.now() / 1000);
  return nowValue - tokenExp <= 0;
}

function setSessionBadgeColor(hours, minutes) {
  if (!hours || !minutes) {
    return (sessionBadgeColor.value = "error");
  }

  let h = parseInt(hours);
  let m = parseInt(minutes);

  if (h === 0) {
    if (m >= 10) {
      sessionBadgeColor.value = "success";
    } else if (m < 10 && m > 5) {
      sessionBadgeColor.value = "warning";
    } else if (m < 5) {
      sessionBadgeColor.value = "error";
    }
  } else {
    sessionBadgeColor.value = "success";
  }
}

async function logout() {
  try {
    await authStore.logout();
  } catch (error) {
    errorFunction(error);
  } finally {
    router.push("/login");
  }
}

onMounted(() => {
  interval.value = setInterval(() => {
    now.value = new Date();
  }, 1000);
});

onBeforeUnmount(() => {
  clearInterval(interval.value);
});
</script>

<style lang="scss" scoped></style>
