export default {
  light: "#ffffff",
  dark: "#222222",
  neutral: "#e0e0e0",

  primary: "#E30615",
  primaryLight: "#f92432",
  primaryDark: "#b30510",

  secondary: "#FFCB02",
  secondaryLight: "#ffd633",
  secondaryDark: "#cca300",

  // primary: '#F7C085',
  // primaryLight: '#ffd633',
  // primaryDark: '#cca300',

  // secondary: '#E30615',
  // secondaryLight: '#f92432',
  // secondaryDark: '#b30510',

  tertiary: "#FFCB02",
  tertiaryLight: "#ffd633",
  tertiaryDark: "#cca300",

  error: "#ff3838",
  info: "#38afff",
  success: "#0dff82",
  warning: "#ffc830",
};
