export default {
  light: "#ffffff",
  dark: "#222222",
  neutral: "#e0e0e0",

  primary: "#ee488c",
  primaryLight: "#f278ab",
  primaryDark: "#ea1a71",

  secondary: "#feb650",
  secondaryLight: "#feca80",
  secondaryDark: "#fe9f1b",

  tertiary: "#f47b67",
  tertiaryLight: "#f8a596",
  tertiaryDark: "#f15337",

  error: "#ff3838",
  info: "#38afff",
  success: "#0dff82",
  warning: "#ffc830",
};
