export default {
  light: "#ffffff",
  dark: "#222222",
  neutral: "#e0e0e0",

  primary: "#F44336",
  secondary: "#F44336",

  error: "#ff3838",
  info: "#38afff",
  success: "#0dff82",
  warning: "#ffc830",
};
