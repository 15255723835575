import { render, staticRenderFns } from "./UserInfoMenu.vue?vue&type=template&id=74807f6b&scoped=true&"
import script from "./UserInfoMenu.vue?vue&type=script&setup=true&lang=js&"
export * from "./UserInfoMenu.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74807f6b",
  null
  
)

export default component.exports