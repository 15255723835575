export default {
  light: "#ffffff",
  dark: "#222222",
  neutral: "#ffffff",

  primary: "#8A9771",
  primaryLight: "#a2ad90",
  primaryDark: "#707b5b",

  secondary: "#39402f",
  secondaryLight: "#545e45",
  secondaryDark: "#1f231a",

  tertiary: "#39402f",
  tertiaryLight: "#545e45",
  tertiaryDark: "#1f231a",

  error: "#ff3838",
  info: "#38afff",
  success: "#0dff82",
  warning: "#ffc830",
};
